/* global window document */
import React from 'react';
// import PropTypes from 'prop-types';
import Link from '../../Services/ActiveLink'; // our version of link
import BookACall from '../BookACall';
import Button from '../Button';

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false,
      sticky: false,
      book: false
    };
  }

  componentDidMount() {
    let lastScrollTop = 0;

    window.onscroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // downscroll code
        this.setState({ sticky: true });
      } else {
        // upscroll code
        this.setState({ sticky: false });
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    };
  }

  render() {
    const { active, sticky, book } = this.state;
    return (
      <div id="navbar" className={`navbar${!active && sticky ? ' --up' : ''}`}>
        <div className="navbar__container">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="navbar__content">
                  <div className="logos">
                    <Link href="/" prefetch>
                      <a href="/" className="logo-Edvice">
                        EDvice
                      </a>
                    </Link>
                    <a href="https://supplynation.org.au/" className="logo-SupplyNation">
                      SupplyNation
                    </a>
                    <a
                      href="https://www.cambridgeenglish.org/exams-and-tests/linguaskill/"
                      className="logo-LingguaskillLogo"
                    >
                      Linguaskill
                    </a>
                    <a href="https://testedenglish.com/" className="logo-TestEd">
                      EDvice
                    </a>
                  </div>
                  <ul id="navigation" className={active ? 'active' : ''}>
                    <li className="current">
                      <Link activeClassName="active" href="/" prefetch>
                        <a
                          onClick={() => {
                            this.setState({ active: false });
                          }}
                          className="navbar__link"
                          href="/"
                        >
                          Home
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link activeClassName="active" href="/about" prefetch>
                        <a
                          onClick={() => {
                            this.setState({ active: false });
                          }}
                          className="navbar__link"
                          href="/about"
                        >
                          About EDvice
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link activeClassName="active" href="/proctoring" prefetch>
                        <a
                          onClick={() => {
                            this.setState({ active: false });
                          }}
                          className="navbar__link"
                          href="/proctoring"
                        >
                          Linguaskill At-Home
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link activeClassName="active" href="/remote-proctoring" prefetch>
                        <a
                          onClick={() => {
                            this.setState({ active: false });
                          }}
                          className="navbar__link"
                          href="/remote-proctoring"
                        >
                          Remote Proctoring
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link activeClassName="active" href="/exams" prefetch>
                        <a
                          onClick={() => {
                            this.setState({ active: false });
                          }}
                          className="navbar__link"
                          href="/exams"
                        >
                          Exams and Tests
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link activeClassName="active" href="/partners" prefetch>
                        <a
                          onClick={() => {
                            this.setState({ active: false });
                          }}
                          className="navbar__link"
                          href="/partners"
                        >
                          Our Partners
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Button color="red" as="link" onClick={() => '/register'}>
                        Register
                      </Button>
                    </li>
                  </ul>
                  <label htmlFor="menu-toggle" className="toggle">
                    <input
                      type="checkbox"
                      id="menu-toggle"
                      name="menu-toggle"
                      onChange={() => {
                        this.setState({ active: !active });
                      }}
                    />
                    <div>
                      <div>
                        <span />
                        <span />
                      </div>
                      <svg>
                        <use xlinkHref="#path" />
                      </svg>
                      <svg>
                        <use xlinkHref="#path" />
                      </svg>
                    </div>
                  </label>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="path">
                      <path d="M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22" />
                    </symbol>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookACall
          open={book}
          onClose={() => {
            this.setState({ book: false });
          }}
        />
      </div>
    );
  }
}
Navbar.defaultProps = {};
Navbar.propTypes = {};
export default Navbar;
