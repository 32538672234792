/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* global fetch fbq document */
import React from 'react';
import ReactDOM from 'react-dom';

class Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      form: {
        name: '',
        email: '',
        phone: '',
        company: '',
        size: '1 - 150 employees',
        interested: 'All Learning & Development Features',
        message: ''
      },
      errors: [],
      sending: false,
      sent: false,
      sendError: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.rules = this.rules.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.body(this.props);
  }

  componentWillReceiveProps(props) {
    this.body(props);
  }

  // eslint-disable-next-line class-methods-use-this
  body(props) {
    if (props.open) {
      document.body.classList.add('modal-active');
    } else {
      document.body.classList.remove('modal-active');
    }
  }

  submit(e) {
    if (this.validate()) {
      const { state } = this;
      const { form } = state;
      state.sending = true;
      this.setState(state);

      fetch(`/api/demo`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ ...form })
      }).then(
        res => {
          if (res.status === 200) {
            this.setState({
              sent: true,
              sending: false,
              form: {
                name: '',
                email: '',
                phone: '',
                message: '',
                company: '',
                size: '',
                interested: ''
              }
            });
            // eslint-disable-next-line no-undef
            if (window.fbq) {
              fbq('track', 'Contact');
            }
            // eslint-disable-next-line no-undef
            window.dataLayer = window.dataLayer || [];
            // eslint-disable-next-line no-undef
            window.dataLayer.push({
              event: 'formSubmissionSuccess',
              formName: 'Book a Demo'
            });
          } else {
            this.setState({
              sending: false,
              sendError: true,
              form: {
                name: '',
                email: '',
                phone: '',
                message: '',
                company: '',
                size: '',
                interested: ''
              }
            });
          }
        },
        () => this.setState({ sending: false, sendError: true })
      );
    }
    e.preventDefault();
  }

  handleChange(e) {
    const { state } = this;
    state.form[e.target.name] = e.target.value;
    this.setState(state);
    e.preventDefault();
  }

  validate() {
    const { state } = this;
    const rules = this.rules();
    const errors = {};
    Object.keys(rules).forEach(key => {
      const vaild = rules[key]();
      if (vaild) {
        errors[key] = vaild;
      }
    });
    state.errors = errors;
    this.setState(state);
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  }

  rules() {
    const { form } = this.state;
    return {
      name: () => {
        return form.name ? '' : 'Please enter a name.';
      },
      phone: () => {
        var expression = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/; // eslint-disable-line
        return expression.test(String(form.phone).toLowerCase())
          ? ''
          : 'Please enter a valid phone.';
      },
      email: () => {
        const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return expression.test(String(form.email).toLowerCase())
          ? ''
          : 'Please enter a valid email address.';
      },
      size: () => {
        return form.size ? '' : 'Please enter company size.';
      },
      interested: () => {
        return form.interested ? '' : 'Please select an option.';
      },
      company: () => {
        return ''; // form.company ? '' : 'Please enter a company.'
      },
      message: () => {
        return ''; // form.message ? '' : 'Please enter a message.'
      }
    };
  }

  render() {
    const { props } = this;
    const { form, errors, sending, sent, sendError } = this.state;
    return (
      <div
        role="dialog"
        id="modal-container"
        className={props.open ? ' active' : ' out'}
        onClick={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <div className="modal-background">
          <div
            className="modal"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className="content">
              <h2>Book a call</h2>
              <p>
                Let us know you know your contact information below and we will be in contact
                shortly to schedule a demo.
              </p>
              {(sending || sent || sendError) && (
                <div className="contact-form__sending">
                  {sending && (
                    <div className="loading">
                      <div className="loading__square" />
                      <div className="loading__square" />
                      <div className="loading__square" />
                      <div className="loading__square" />
                      <div className="loading__square" />
                      <div className="loading__square" />
                      <div className="loading__square" />
                    </div>
                  )}
                  {sending && <span>Sending</span>}
                  {sent && (
                    <span>
                      Your message has been sent.
                      <br />
                      <br />
                      <button
                        type="button"
                        onClick={() => {
                          if (props.onClose) {
                            props.onClose();
                          }
                        }}
                        className="ui button"
                      >
                        Close
                      </button>
                    </span>
                  )}
                  {sendError && (
                    <span style={{ margin: 10, textAlign: 'center' }}>
                      <p>An error has occured whilst trying to send your email.</p>
                    </span>
                  )}
                </div>
              )}
              <form onSubmit={this.submit}>
                <div className="fields">
                  <div className="field">
                    <label>
                      Name
                      {Object.keys(errors).length > 0 &&
                        Object.keys(errors).map(k => {
                          if (k === 'name') {
                            return (
                              <span className="error" key={k}>
                                {errors[k]}
                              </span>
                            );
                          }
                          return '';
                        })}
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name:"
                      value={form.name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label>
                      Phone
                      {Object.keys(errors).length > 0 &&
                        Object.keys(errors).map(k => {
                          if (k === 'phone') {
                            return (
                              <span className="error" key={k}>
                                {errors[k]}
                              </span>
                            );
                          }
                          return '';
                        })}
                    </label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone:"
                      value={form.phone}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label>
                      Email
                      {Object.keys(errors).length > 0 &&
                        Object.keys(errors).map(k => {
                          if (k === 'email') {
                            return (
                              <span className="error" key={k}>
                                {errors[k]}
                              </span>
                            );
                          }
                          return '';
                        })}
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email:"
                      value={form.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label>
                      Company Name
                      {Object.keys(errors).length > 0 &&
                        Object.keys(errors).map(k => {
                          if (k === 'company') {
                            return (
                              <span className="error" key={k}>
                                {errors[k]}
                              </span>
                            );
                          }
                          return '';
                        })}
                    </label>
                    <input
                      type="text"
                      name="company"
                      placeholder="Company: (Optional)"
                      value={form.company}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="field">
                    <label>
                      Company Size
                      {Object.keys(errors).length > 0 &&
                        Object.keys(errors).map(k => {
                          if (k === 'size') {
                            return (
                              <span className="error" key={k}>
                                {errors[k]}
                              </span>
                            );
                          }
                          return '';
                        })}
                    </label>
                    <select
                      name="size"
                      value={form.size}
                      onChange={this.handleChange}
                      placeholder="Company size:"
                    >
                      <option>1 - 150 employees</option>
                      <option>151 - 499 employees</option>
                      <option>500 - 999 employees</option>
                      <option>1,000 - 4,999 employees</option>
                      <option>5000 - 10,000 employees</option>
                      <option>10,000 + employees</option>
                    </select>
                    <label>
                      Interested In:
                      {Object.keys(errors).length > 0 &&
                        Object.keys(errors).map(k => {
                          if (k === 'interested') {
                            return (
                              <span className="error" key={k}>
                                {errors[k]}
                              </span>
                            );
                          }
                          return '';
                        })}
                    </label>
                    <select name="interested" value={form.interested} onChange={this.handleChange}>
                      <option>All Learning & Development Features</option>
                      <option>Employee Induction / Training</option>
                      <option>Succession Planning</option>
                      <option>Employee upskilling</option>
                    </select>
                  </div>
                  <div className="field">
                    <label>
                      Message
                      {Object.keys(errors).length > 0 &&
                        Object.keys(errors).map(k => {
                          if (k === 'message') {
                            return (
                              <span className="error" key={k}>
                                {errors[k]}
                              </span>
                            );
                          }
                          return '';
                        })}
                    </label>
                    <textarea
                      name="message"
                      value={form.message}
                      onChange={this.handleChange}
                      placeholder="Message: (Optional)"
                    />
                  </div>
                </div>
                <div className="modal-actions">
                  <button
                    type="button"
                    className="ui button --primary"
                    style={{ float: 'left' }}
                    onClick={() => {
                      if (props.onClose) {
                        props.onClose();
                      }
                    }}
                  >
                    Close
                  </button>
                  <button type="submit" className="button --green">
                    Send
                  </button>
                </div>
              </form>
              {/* {this.props.children} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class BodyEnd extends React.PureComponent {
  componentDidMount() {
    this.popup = document.createElement('div');
    document.body.appendChild(this.popup);
    this.RenderPortal();
  }

  componentDidUpdate() {
    this.RenderPortal();
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.popup);
    document.body.removeChild(this.popup);
  }

  RenderPortal() {
    ReactDOM.render(<Modal {...this.props} />, this.popup);
  }

  render() {
    return null;
  }
}

export default BodyEnd;
