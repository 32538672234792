/* eslint-disable global-require */
import React from 'react';
// import PropTypes from 'prop-types';
import Link from '../../Services/ActiveLink'; // our version of link

const classNames = require('classnames');

const Footer = () => {
  const className = classNames({
    footer: true
  });

  return (
    <React.Fragment>
      <div className={className}>
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-xs-12">
              <Link href="/" prefetch>
                <a href="/" className="logo">
                  EDvice
                </a>
              </Link>
            </div>
            <div className="col-sm-4 col-xs-12">
              <div className="suppliers">
                <a
                  href="https://www.cambridgeenglish.org/exams-and-tests/linguaskill/"
                  className="supplier-logo"
                  target="_blank"
                  rel="noopener noreferrer"
                  // eslint-disable-next-line global-require
                  style={{ backgroundImage: `url(${require('./cambridge.svg')})` }}
                >
                  Linguaskill
                </a>

                <a
                  href="https://supplynation.org.au"
                  className="supplier-logo"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    // eslint-disable-next-line global-require
                    backgroundImage: `url(${require('./supply nation-logo-reversed.png')})`
                  }}
                >
                  Supply Nation
                </a>

                <a
                  href="https://www.proctoru.com/"
                  className="supplier-logo"
                  target="_blank"
                  rel="noopener noreferrer"
                  // eslint-disable-next-line global-require
                  style={{
                    backgroundImage: `url(${require('./TestEd_Logo_Inline_Reversed.png')})`
                  }}
                >
                  TestEd English
                </a>
              </div>
            </div>
            <div className="col-sm-2 col-xs-12">
              <ul>
                <li>
                  <Link href="/" prefetch>
                    <a href="/">Home</a>
                  </Link>
                </li>
                <li>
                  <Link href="/about" prefetch>
                    <a href="/about">About EDvice</a>
                  </Link>
                </li>
                <li>
                  <Link href="/exams" prefetch>
                    <a href="/exams">Exams and Tests</a>
                  </Link>
                </li>
                <li>
                  <Link href="/partners" prefetch>
                    <a href="/partners">Our Partners</a>
                  </Link>
                </li>
                <li>
                  <Link href="/register" prefetch>
                    <a href="/register">Register</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-3 col-xs-12">
              <div className="find-us-at">
                <strong>Contact Us</strong>

                <a href="mailto: enquiries@edvice.com.au">E | enquiries@edvice.com.au</a>

                <a href="tel:1300 198 700">P | 1300 198 700</a>

                <a
                  href="https://goo.gl/maps/1u6Qzq5pScB36qVc7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  A | 1/7 Lonsdale St, Braddon ACT 2612
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              2020 All Rights Reserved | Site by&nbsp;
              <a href="https://pursuittechnology.com" target="_blank" rel="noopener noreferrer">
                Pursuit Technology
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
Footer.defaultProps = {};
Footer.propTypes = {};
export default Footer;
